import {
    Button,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import React from 'react'
import { useAuth } from '../../providers/AuthProvider'

export default function NavLinks() {

    const { user } = useAuth();

    return (
        <>

            {user.type.toLowerCase() === "admin" ?
                <Link to={'/admin/members'}>
                    <Button bg='primary.800' borderRadius='md' colorScheme='primary'>
                        Members
                    </Button>
                </Link>
                : user.type.toLowerCase() === "location" ?
                    <>
                        <Link to={'/location/members'}>
                            <Button bg='primary.800' borderRadius='md' colorScheme='primary'>
                                Members
                            </Button>
                        </Link>
                    </>
                    : <></>}

            {user.type.toLowerCase() === "admin"
                ?
                <>
                    <Link to={'/locations'}>
                        <Button bg='primary.800' borderRadius='md' colorScheme='primary'>
                            Locations
                        </Button>
                    </Link>
                    <Link to={'/admin/report'}>
                        <Button bg='primary.800' borderRadius='md' colorScheme='primary'>
                            Report
                        </Button>
                    </Link>
                </>
                :
                <></>}

            {/* 
            {user.type.toLowerCase() === "location"
                ?
                <Link to={'/members'}>
                    <Button bg='primary.800' borderRadius='md' colorScheme='primary'>
                        Members
                    </Button>
                </Link>
                :
                <></>} */}

            {user.type.toLowerCase() === "location"
                ?
                <Link to={'/users/add'}>
                    <Button bg='primary.800' borderRadius='md' colorScheme='primary'>
                        Add New Member
                    </Button>
                </Link>
                :
                <></>}
           
            {user.type.toLowerCase() === "location"
                ?
                <Link to={'/locations/report'}>
                    <Button bg='primary.800' borderRadius='md' colorScheme='primary'>
                        Report
                    </Button>
                </Link>
                :
                <></>}

            <Link to={'/schemes'}>
                <Button bg='primary.800' borderRadius='md' colorScheme='primary'>
                    Schemes
                </Button>
            </Link>


        </>
    )
}
