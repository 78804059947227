import { Box, Button, Card, Container, HStack, Input, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useAuth } from '../../providers/AuthProvider'
import axios from 'axios';
import { url } from '../../config';
import { toast } from 'react-toastify';
import { useStoreLocations } from '../../providers/LocationProvider';
import moment from 'moment';

export default function AdminReport() {

    const { token } = useAuth();
    var { location } = useStoreLocations();

    const [date, setDate] = React.useState('');
    const [year, setYear] = React.useState('');
    const [selectedLocation, setSelectedLocation] = React.useState('-1');

    const [loading, setLoading] = React.useState(false);
    const [enrolled, setEnrolled] = React.useState([]);
    const [collection, setCollection] = React.useState([]);
    const [pendingCollection, setPendingCollection] = React.useState([]);
    const [pending, setPending] = React.useState([]);
    const [newUsers, setNewUsers] = React.useState([]);

    function getReportData() {
        if (date === '') {
            alert('Please select a month');
            return;
        }

        if (year === '') {
            alert('Please select a year');
            return;
        }

        setLoading(true);
        // fetch report data    
        axios.post(`${url}/report`,
            { month: date, year: year, location: selectedLocation }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setEnrolled(response.data.enrolled);
                setCollection(response.data.collection);
                setPendingCollection(response.data.pendingCollection);
                setPending(response.data.pendingPayment); 
                
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching report data:', error);
                // alert('Failed to fetch report data');
                toast.error('Failed to fetch report data');
            });
    }

    // React.useEffect(() => {
    //     getReportData();
    // }, [date]);

    return (
        <Container maxW='8xl' my='6'>
            <VStack width='100%' spacing={4}>

                <Card borderRadius='md' p='4' width={'100%'}>
                    <HStack justifyContent='space-between' w='100%'>
                        <Text fontWeight='bold' className='customfont' fontSize='2xl'>Report</Text>
                    </HStack>
                </Card>

                <Card borderRadius='md' p='4' width={'100%'}>
                    <HStack justifyContent='start' w='100%'>
                        <Text fontWeight='bold' className='customfont' fontSize='md'>Select  </Text>
                        <Box maxW='50%'>
                            {/* list of dropdown for months with value being month number and display is month name */}
                            <Input as='select'
                                value={year}
                                onChange={(e) => {
                                    setYear(e.target.value);
                                    // getReportData();
                                }}>
                                <option value=''>Select Year</option>
                                <option value='2020'>2020</option>
                                <option value='2021'>2021</option>
                                <option value='2022'>2022</option>
                                <option value='2023'>2023</option>
                                <option value='2024'>2024</option>
                                <option value='2025'>2025</option>
                                <option value='2026'>2026</option>
                                <option value='2027'>2027</option>
                                <option value='2028'>2028</option>
                                <option value='2029'>2029</option>
                                <option value='2030'>2030</option>
                                <option value='2031'>2031</option>
                                <option value='2032'>2032</option>
                                <option value='2033'>2033</option>
                                <option value='2034'>2034</option>
                                <option value='2035'>2035</option>
                            </Input>
                        </Box>
                        <Box maxW='50%'>
                            {/* list of dropdown for months with value being month number and display is month name */}
                            <Input as='select'
                                value={date}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                    // getReportData();
                                }}>
                                <option value=''>Select Month</option>
                                <option value='1'>January</option>
                                <option value='2'>February</option>
                                <option value='3'>March</option>
                                <option value='4'>April</option>
                                <option value='5'>May</option>
                                <option value='6'>June</option>
                                <option value='7'>July</option>
                                <option value='8'>August</option>
                                <option value='9'>September</option>
                                <option value='10'>October</option>
                                <option value='11'>November</option>
                                <option value='12'>December</option>
                            </Input>
                        </Box>
                        <Box maxW='50%'>
                            <Input
                                as='select'
                                value={selectedLocation}
                                onChange={(e) => {
                                    setSelectedLocation(e.target.value);
                                    // getReportData();
                                }}>
                                <option value=''>Select Location</option>
                                <option value='-1'>All Locations</option>
                                {location.map((l, idx) => {
                                    return <option value={l.id}>{l.name}</option>
                                })}
                            </Input>
                        </Box>

                        <Button colorScheme='primary' onClick={getReportData}>Get Report</Button>
                    </HStack>
                </Card>

                {!loading && enrolled !== null && collection.length > 0 &&
                    <HStack w='100%'>
                        <Card p='3'>
                            <Text>Total Collected : Rs. {collection[0].collection || 0}</Text>
                        </Card>
                        <Card p='3'>
                            <Text>Pending Collection : Rs. {pendingCollection[0].collection || 0}</Text>
                        </Card>
                        <Card p='3'>
                            <Text>Total Enrolled : {enrolled.length || 0} Users</Text>
                        </Card>
                    </HStack>
                }

                {!loading && pending !== null && pending.length > 0 &&


                    <Card width={'100%'}>

                        <table>
                            <thead>
                                <tr>
                                    <th py='4' fontSize='sm'>Id</th>
                                    <th py='4' fontSize='sm'>Plan</th>
                                    <th py='4' fontSize='sm'>Name</th>
                                    <th py='4' fontSize='sm'>Number</th>
                                    <th py='4' fontSize='sm'>Email</th>
                                    <th py='4' fontSize='sm'>Amount</th>
                                    <th py='4' fontSize='sm'>Payment Date</th>
                                    <th py='4' fontSize='sm'>User Paid</th>
                                    <th py='4' fontSize='sm'>Store Accepted</th>
                                    <th py='4' fontSize='sm'>Store</th>
                                    <th py='4' fontSize='sm'>Enrollment Date</th>
                                    <th py='4' fontSize='sm'>New User ?</th>
                                </tr>
                            </thead>

                            <tbody>
                                {pending.map((u, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td py='4' fontSize='sm'>{u.id_number}</td>
                                            <td py='4' fontSize='sm'> {u.scheme_name}</td>
                                            <td py='4' fontSize='sm'> {u.name}</td>
                                            <td py='4' fontSize='sm'> {u.phone}</td>
                                            <td py='4' fontSize='sm'> {u.e_mail}</td>
                                            <td py='4' fontSize='sm'> {u.monthly_price}</td>
                                            <td py='4' fontSize='sm'> {u.payment_date}</td>
                                            <td py='4' fontSize='sm'> {u.paid_by_user ? 'YES' : 'NO'}</td>
                                            <td py='4' fontSize='sm'> {u.accepted_by_store ? 'YES' : 'NO'}</td>
                                            <td py='4' fontSize='sm'> {u.locationname}</td>
                                            <td py='4' fontSize='sm'> {moment(u.enrollment_date).format('DD-MM-YYYY')}</td>
                                            <td py='4' fontSize='sm'> {moment(u.enrollment_date).format('YYYY-MM') === moment().format('YYYY-MM') ? 'YES' : 'NO'}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Card>
                }


            </VStack>

        </Container>
    )
}
